import reducer from "./reducers";

import { createStore } from "redux";
import { persistStore, persistCombineReducers } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["userProfile", "orders"],
};

const persistedReducer = persistCombineReducers(persistConfig, reducer);

export default () => {
  const store = createStore(persistedReducer);
  let persistor = persistStore(store);

  return { store, persistor };
};
