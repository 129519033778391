import React from "react";

const SignIn = React.lazy(() => import("./App/pages/Authentication/SignIn"));
const ErrorPage = React.lazy(() => import("./App/pages/Authentication/ErrorPage"));

const route = [
  { path: "/login", exact: true, name: "Signin", component: SignIn },
  { path: "/error", exact: true, name: "Error Page", component: ErrorPage },
];

export default route;
