import navReducer from "./navReducer";
import userProfileReducer from "./userProfileReducer";
import contractorListReducer from "./contractorListReducer";
import customerListReducer from "./customerListReducer";
import allItemsReducer from "./allItemsReducer";
import allOrdersReducer from "./allOrdersReducer";

const reducers = {
  nav: navReducer,
  userProfile: userProfileReducer,
  contractorsList: contractorListReducer,
  customersList: customerListReducer,
  items: allItemsReducer,
  orders: allOrdersReducer,
};

export default reducers;
