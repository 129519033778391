const initialState = {
  fetched: false,
  data: {},
};

const allItemsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SAVE_ORDERS":
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default allItemsReducer;
