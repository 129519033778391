export const COLLAPSE_MENU = "COLLAPSE_MENU";
export const COLLAPSE_TOGGLE = "COLLAPSE_TOGGLE";
export const FULL_SCREEN = "FULL_SCREEN";
export const FULL_SCREEN_EXIT = "FULL_SCREEN_EXIT";
export const CHANGE_LAYOUT = "CHANGE_LAYOUT";
export const NAV_CONTENT_LEAVE = "NAV_CONTENT_LEAVE";
export const NAV_COLLAPSE_LEAVE = "NAV_COLLAPSE_LEAVE";
export const SAVE_USER_PROFILE = "SAVE_USER_PROFILE";
export const SAVE_CONTRACTOR_LIST = "SAVE_CONTRACTOR_LIST";
export const SAVE_CUSTOMER_LIST = "SAVE_CUSTOMER_LIST";
export const SAVE_ITEMS = "SAVE_ITEMS";
export const SAVE_ORDERS = "SAVE_ORDERS";
